import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import ElementTag from "./ElementTag";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function StripePaymentStatus() {
  return (
    <Elements stripe={stripePromise}>
      <ElementTag />
    </Elements>
  );
}

export default StripePaymentStatus;
